import {
    EmployeeChild,
    EmployeeProfessionalQualification,
    Employment,
    SoftwareAccess,
    BankAccount,
    HealthStatus,
    LifeInsurance,
    DrivingLicence,
    Skill,
    MaritalStatus,
    Religion,
    EmployeeEmergencyContact,
    PatronSaintDay, CalendarEntry, PersonalDocument,
    EmployeeWorkNoteStatistic,
    VehicleRide, Vaccine,
    EmployeeTravelExpense
} from './entities';
import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Company} from './company';
import {User} from './user';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {AssociationManyToMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many-to-many';
import {Validators} from '@angular/forms';
import {CustomValidators} from '../validators/custom-validators';
import {Citizenship} from './citizenship';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {EmployeeRepository} from '../repositories/employee.repository';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {MediaObject} from './media-object';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('employees')
@CrossDependency(() => {
    return {
        company: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Company
        },
        user: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        professionalQualification: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeProfessionalQualification
        },
        children: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: EmployeeChild
        },
        softwareAccesses: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: SoftwareAccess
        },
        skills: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: Skill
        },
        employment: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employment
        },
        bankAccount: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: BankAccount
        },
        healthStatus: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: HealthStatus,
            maxDepth: 2
        },
        lifeInsurance: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: LifeInsurance
        },
        drivingLicence: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: DrivingLicence
        },
        religion: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Religion
        },
        emergencyContact: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeEmergencyContact
        },
        patronSaintDay: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PatronSaintDay
        },
        citizenship: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Citizenship
        },
        calendarEntries: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: CalendarEntry
        },
        calendarEntriesFiltered: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: CalendarEntry
        },
        personalDocuments: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: PersonalDocument
        },
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        },
        workNoteStatisticCurrentYearCommendation: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeWorkNoteStatistic
        },
        workNoteStatisticCurrentYearBehaviorWorkplace: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeWorkNoteStatistic
        },
        workNoteStatisticCurrentYearTimeWorx: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeWorkNoteStatistic
        },
        workNoteStatisticCurrentYearWorkViolation: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeWorkNoteStatistic
        },
        vehicleRides: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: VehicleRide
        },
        typeOfFirstVaccine: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vaccine
        },
        typeOfSecondVaccine: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vaccine
        },
        typeOfThirdVaccine: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vaccine
        },
        employeeTravelExpenses: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: EmployeeTravelExpense
        },
    };
})
@EntityDefaultFilters({
    'exists[employment.dismissalType]': false
})
@Repository(EmployeeRepository)
export class Employee {

    @AssociationOne(Company)
    protected company: Company;

    @AssociationOne(User)
    protected user: User;

    @AssociationOne(EmployeeProfessionalQualification)
    protected professionalQualification: EmployeeProfessionalQualification;

    @AssociationMany(EmployeeChild)
    protected children: EmployeeChild[];

    @AssociationOne(Employment)
    protected employment: Employment;

    @AssociationManyToMany(SoftwareAccess)
    protected softwareAccesses: SoftwareAccess[];

    @AssociationManyToMany(Skill)
    protected skills: Skill[];

    @AssociationOne(MaritalStatus)
    protected maritalStatus: MaritalStatus;

    @AssociationOne(BankAccount)
    protected bankAccount: BankAccount;

    @AssociationOne(HealthStatus)
    protected healthStatus: HealthStatus;

    @AssociationOne(LifeInsurance)
    protected lifeInsurance: LifeInsurance;

    @AssociationOne(DrivingLicence)
    protected drivingLicence: DrivingLicence;

    @AssociationOne(Religion)
    protected religion: Religion;

    @AssociationOne(EmployeeEmergencyContact)
    protected emergencyContact: EmployeeEmergencyContact;

    @AssociationOne(PatronSaintDay)
    protected patronSaintDay: PatronSaintDay;

    @AssociationOne(Citizenship)
    protected citizenship: Citizenship;

    @AssociationMany(CalendarEntry)
    protected calendarEntries: CalendarEntry[];

    @AssociationMany(CalendarEntry)
    protected calendarEntriesFiltered: CalendarEntry[];

    @AssociationMany(PersonalDocument)
    protected personalDocuments: PersonalDocument[];

    @AssociationMany(MediaObject)
    protected medias: MediaObject[];

    @AssociationOne(EmployeeWorkNoteStatistic)
    protected workNoteStatisticCurrentYearTimeWorx: EmployeeWorkNoteStatistic;

    @AssociationOne(EmployeeWorkNoteStatistic)
    protected workNoteStatisticCurrentYearWorkViolation: EmployeeWorkNoteStatistic;

    @AssociationOne(EmployeeWorkNoteStatistic)
    protected workNoteStatisticCurrentYearCommendation: EmployeeWorkNoteStatistic;

    @AssociationOne(EmployeeWorkNoteStatistic)
    protected workNoteStatisticCurrentYearBehaviorWorkplace: EmployeeWorkNoteStatistic;

    @AssociationMany(VehicleRide)
    protected vehicleRides: VehicleRide[];

    @AssociationOne(Vaccine)
    protected typeOfFirstVaccine: Vaccine;

    @AssociationOne(Vaccine)
    protected typeOfSecondVaccine: Vaccine;

    @AssociationOne(Vaccine)
    protected typeOfThirdVaccine: Vaccine;

    @AssociationMany(EmployeeTravelExpense)
    protected employeeTravelExpenses: EmployeeTravelExpense[];

    protected id: string;
    protected firstName: string;
    protected lastName: string;
    protected personalEmail: string;
    protected businessEmail: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected birthDate: Date;
    protected birthPlace: string;
    protected sex: string;
    protected uniqueMasterCitizenNumber: string;
    protected healthInsuranceNumber: string;
    protected spouseFullName: string;
    protected spousePhoneNumber: string;
    protected personalPhoneNumber: string;
    protected businessPhoneNumber: string;
    protected notice: string;
    protected numberOfChildren: number;
    protected address: string;
    protected city: string;
    protected tShirt: string;
    protected sweatshirt: string;
    protected workUniform: string;
    protected personalMobilePhoneNumber: string;
    protected idCardDifferentAddress: boolean;
    protected idCardPostalCode: string;
    protected idCardCity: string;
    protected idCardAddress: string;
    protected housingStatus: string;
    protected hasBusinessPhone: boolean;
    protected businessMobilePhoneNumber: string;
    protected daysOfService: number;
    protected employeeNumber: number;
    protected holiday: number;
    protected availableHoliday: number;
    protected pastHoliday: number;
    protected availablePastHoliday: number;
    protected hasPaymentDeductionForMoron: boolean;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected overtime: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected approvedOvertime: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected availableApprovedOvertime: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected timeAbsent: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected approvedTimeAbsent: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected availableApprovedTimeAbsent: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected overtimeTimeAbsentDifference: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected approvedOvertimeTimeAbsentDifference: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected availableApprovedOvertimeTimeAbsentDifference: string;

    protected offDays: number;
    protected totalAvailableDays: number;
    protected availableOffDays: number;
    protected monthlySalaryCalculationInternalNumber: string;
    protected secondBusinessMobilePhoneNumber: string;
    protected isMonthlySalaryCalculationRegulated: boolean;
    protected yearsOfService: any;
    protected sickLeaveCount: number;
    protected timeWorxCount: number;
    protected workViolationCount: number;
    protected commendationOfSuperiorCount: number;
    protected behaviorWorkplaceCount: number;
    protected overtimeCount: number;
    protected absenceItemCount: number;
    protected vehicleRideTotalMileage: string;
    protected vehicleRideCurrentYearMileage: string;
    protected vehicleRideItemCount: number;
    protected maternityLeaveCount: number;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateOfFirstVaccination: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateOfSecondVaccination: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateOfTheThirdVaccination: Date;
    protected getOverCovid: boolean;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected startCovidDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected endCovidDate: Date;

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): Employee {
        this.id = id;
        return this;
    }

    public getFirstName(): string | undefined {
        return this.firstName;
    }

    public setFirstName(firstName: string): Employee {
        this.firstName = firstName;
        return this;
    }

    public getLastName(): string | undefined {
        return this.lastName;
    }

    public setLastName(lastName: string): Employee {
        this.lastName = lastName;
        return this;
    }

    public getFullName(): string {
        return this.getFirstName() + ' ' + this.getLastName();
    }

    public getPersonalEmail(): string | undefined {
        return this.personalEmail;
    }

    public setPersonalEmail(email: string): Employee {
        this.personalEmail = email;
        return this;
    }

    public getBusinessEmail(): string | undefined {
        return this.businessEmail;
    }

    public setBusinessEmail(businessEmail: string): Employee {
        this.businessEmail = businessEmail;
        return this;
    }

    public setBirthDate(birthDate: Date) {
        this.birthDate = birthDate;
        return this;
    }

    public getBirthDate(): Date | undefined {
        return this.birthDate;
    }

    public setBirthPlace(birthPlace: string): Employee {
        this.birthPlace = birthPlace;
        return this;
    }

    public getBirthPlace(): string | undefined {
        return this.birthPlace;
    }

    public setCitizenship(citizenship: Citizenship): Employee {
        this.citizenship = citizenship;
        return this;
    }

    public getCitizenship(): Citizenship|undefined {
        return this.citizenship;
    }

    public setSex(sex: string): Employee {
        this.sex = sex;
        return this;
    }

    public getSex(): string | undefined {
        return this.sex;
    }

    public setUniqueMasterCitizenNumber(uniqueMasterCitizenNumber: string): Employee {
        this.uniqueMasterCitizenNumber = uniqueMasterCitizenNumber;
        return this;
    }

    public getUniqueMasterCitizenNumber(): string | undefined {
        return this.uniqueMasterCitizenNumber;
    }

    public getHealthInsuranceNumber(): string | undefined {
        return this.healthInsuranceNumber;
    }

    public setHealthInsuranceNumber(healthInsuranceNumber: string): Employee {
        this.healthInsuranceNumber = healthInsuranceNumber;
        return this;
    }

    public setMaritalStatus(maritalStatus: MaritalStatus): Employee {
        this.maritalStatus = maritalStatus;
        return this;
    }

    public getMaritalStatus(): MaritalStatus | undefined {
        return this.maritalStatus;
    }

    public setSpouseFullName(spouseFullName: string): Employee {
        this.spouseFullName = spouseFullName;
        return this;
    }

    public getSpouseFullName(): string | undefined {
        return this.spouseFullName;
    }

    public setSpousePhoneNumber(spousePhoneNumber: string): Employee {
        this.spousePhoneNumber = spousePhoneNumber;
        return this;
    }

    public getSpousePhoneNumber(): string | undefined {
        return this.spousePhoneNumber;
    }

    public setReligion(religion: Religion): Employee {
        this.religion = religion;
        return this;
    }

    public getReligion(): Religion|undefined {
        return this.religion;
    }

    public setPersonalPhoneNumber(personalPhoneNumber: string): Employee {
        this.personalPhoneNumber = personalPhoneNumber;
        return this;
    }

    public getPersonalPhoneNumber(): string | undefined {
        return this.personalPhoneNumber;
    }

    public setPersonalMobilePhoneNumber(personalMobilePhoneNumber: string): Employee {
        this.personalMobilePhoneNumber = personalMobilePhoneNumber;
        return this;
    }

    public getPersonalMobilePhoneNumber(): string | undefined {
        return this.personalMobilePhoneNumber;
    }

    public setBusinessPhoneNumber(businessPhoneNumber: string): Employee {
        this.businessPhoneNumber = businessPhoneNumber;
        return this;
    }

    public getBusinessPhoneNumber(): string | undefined {
        return this.businessPhoneNumber;
    }

    public setHasBusinessPhone(hasBusinessPhone: boolean): Employee {
        this.hasBusinessPhone = hasBusinessPhone;
        return this;
    }

    public getHasBusinessPhone(): boolean {
        return this.hasBusinessPhone;
    }

    public setBusinessMobilePhoneNumber(businessMobilePhoneNumber: string): Employee {
        this.businessMobilePhoneNumber = businessMobilePhoneNumber;
        return this;
    }

    public getBusinessMobilePhoneNumber(): string | undefined {
        return this.businessMobilePhoneNumber;
    }

    public setNotice(notice: string): Employee {
        this.notice = notice;
        return this;
    }

    public getNotice(): string | undefined {
        return this.notice;
    }

    public setChildren(children: EmployeeChild[]): Employee {
        this.children = children;
        return this;
    }

    public getChildren(): EmployeeChild[] | undefined {
        return this.children;
    }

    public getSoftwareAccesses(): SoftwareAccess[] {
        return this.softwareAccesses;
    }

    public setSoftwareAccesses(softwareAccesses: SoftwareAccess[]): Employee {
        this.softwareAccesses = softwareAccesses;
        return this;
    }

    public getSkills(): Skill[] {
        return this.skills;
    }

    public setSkills(skills: Skill[]): Employee {
        this.skills = skills;
        return this;
    }

    public setProfessionalQualification(professionalQualification: EmployeeProfessionalQualification): Employee {
        this.professionalQualification = professionalQualification;
        return this;
    }

    public getProfessionalQualification(): EmployeeProfessionalQualification {
        return this.professionalQualification;
    }

    public setEmployment(employment: Employment): Employee {
        this.employment = employment;
        return this;
    }

    public getEmployment(): Employment | undefined {
        return this.employment;
    }

    public setPersonalDocuments(personalDocuments: PersonalDocument[]): Employee {
        this.personalDocuments = personalDocuments;
        return this;
    }

    public getPersonalDocuments(): PersonalDocument[] | undefined {
        return this.personalDocuments;
    }

    public setNumberOfChildren(numberOfChildren: number): Employee {
        this.numberOfChildren = numberOfChildren;
        return this;
    }

    public getNumberOfChildren(): number {
        return this.numberOfChildren;
    }

    public setAddress(address: string): Employee {
        this.address = address;
        return this;
    }

    public getAddress(): string {
        return this.address;
    }

    public setCity(city: string): Employee {
        this.city = city;
        return this;
    }

    public getCity(): string {
        return this.city;
    }

    public setTShirt(tShirt: string): Employee {
        this.tShirt = tShirt;
        return this;
    }

    public getTShirt(): string {
        return this.tShirt;
    }

    public setSweatshirt(sweatshirt: string): Employee {
        this.sweatshirt = sweatshirt;
        return this;
    }

    public getSweatshirt(): string {
        return this.sweatshirt;
    }

    public setWorkUniform(workUniform: string): Employee {
        this.workUniform = workUniform;
        return this;
    }

    public getWorkUniform(): string {
        return this.workUniform;
    }

    public setBankAccount(bankAccount: BankAccount): Employee {
        this.bankAccount = bankAccount;
        return this;
    }

    public getBankAccount(): BankAccount {
        return this.bankAccount;
    }

    public setHealthStatus(healthStatus: HealthStatus): Employee {
        this.healthStatus = healthStatus;
        return this;
    }

    public getHealthStatus(): HealthStatus|undefined {
        return this.healthStatus;
    }

    public setLifeInsurance(lifeInsurance: LifeInsurance): Employee {
        this.lifeInsurance = lifeInsurance;
        return this;
    }

    public getLifeInsurance(): LifeInsurance|undefined {
        return this.lifeInsurance;
    }

    public setDrivingLicence(drivingLicence: DrivingLicence): Employee {
        this.drivingLicence = drivingLicence;
        return this;
    }

    public getDrivingLicence(): DrivingLicence|undefined {
        return this.drivingLicence;
    }

    public getEmergencyContact(): EmployeeEmergencyContact|undefined {
        return this.emergencyContact;
    }

    public setEmergencyContact(emergencyContact: EmployeeEmergencyContact): Employee {
        this.emergencyContact = emergencyContact;
        return this;
    }

    public setIdCardDifferentAddress(idCardDifferentAddress: boolean): Employee {
        this.idCardDifferentAddress = idCardDifferentAddress;
        return this;
    }

    public getIdCardDifferentAddress(): boolean {
        return this.idCardDifferentAddress;
    }

    public setIdCardPostalCode(idCardPostalCode: string): Employee {
        this.idCardPostalCode = idCardPostalCode;
        return this;
    }

    public getIdCardPostalCode(): string {
        return this.idCardPostalCode;
    }

    public setIdCardCity(idCardCity: string): Employee {
        this.idCardCity = idCardCity;
        return this;
    }

    public getIdCardCity(): string {
        return this.idCardCity;
    }

    public setIdCardAddress(idCardAddress: string): Employee {
        this.idCardAddress = idCardAddress;
        return this;
    }

    public getIdCardAddress(): string {
        return this.idCardAddress;
    }

    public setHousingStatus(housingStatus: string): Employee {
        this.housingStatus = housingStatus;
        return this;
    }

    public getHousingStatus(): string {
        return this.housingStatus;
    }

    public getGetterMethodName(property) {
        return 'get' + property.charAt(0).toUpperCase() + property.slice(1);
    }

    public getSetterMethodName(property) {
        return 'set' + property.charAt(0).toUpperCase() + property.slice(1);
    }

    public setPatronSaintDay(patronSaintDay: PatronSaintDay): Employee {
        this.patronSaintDay = patronSaintDay;
        return this;
    }

    public getPatronSaintDay(): PatronSaintDay|undefined {
        return this.patronSaintDay;
    }

    public setEmployeeNumber(employeeNumber: number): Employee {
        this.employeeNumber = employeeNumber;
        return this;
    }

    public getEmployeeNumber(): number {
        return this.employeeNumber;
    }

    public setHoliday(holiday: number): Employee {
        this.holiday = holiday;
        return this;
    }

    public getHoliday(): number {
        return this.holiday;
    }

    public setAvailableHoliday(availableHoliday: number): Employee {
        this.availableHoliday = availableHoliday;
        return this;
    }

    public getAvailableHoliday(): number {
        return this.availableHoliday;
    }

    public setPastHoliday(pastHoliday: number): Employee {
        this.pastHoliday = pastHoliday;
        return this;
    }

    public getPastHoliday(): number {
        return this.pastHoliday;
    }

    public setAvailablePastHoliday(availablePastHoliday: number): Employee {
        this.availablePastHoliday = availablePastHoliday;
        return this;
    }

    public getAvailablePastHoliday(): number {
        return this.availablePastHoliday;
    }

    public getOvertime(): string {
        return this.overtime;
    }

    public setOvertime(overtime: string): Employee {
        this.overtime = overtime;
        return this;
    }

    public getApprovedOvertime(): string {
        return this.approvedOvertime;
    }

    public setApprovedOvertime(approvedOvertime: string): Employee {
        this.approvedOvertime = approvedOvertime;
        return this;
    }

    public getAvailableApprovedOvertime(): string {
        return this.availableApprovedOvertime;
    }

    public setAvailableApprovedOvertime(availableApprovedOvertime: string): Employee {
        this.availableApprovedOvertime = availableApprovedOvertime;
        return this;
    }

    public getTimeAbsent(): string {
        return this.timeAbsent;
    }

    public setTimeAbsent(timeAbsent: string): Employee {
        this.timeAbsent = timeAbsent;
        return this;
    }

    public getApprovedTimeAbsent(): string {
        return this.approvedTimeAbsent;
    }

    public setApprovedTimeAbsent(approvedTimeAbsent: string): Employee {
        this.approvedTimeAbsent = approvedTimeAbsent;
        return this;
    }

    public getAvailableApprovedTimeAbsent(): string {
        return this.availableApprovedTimeAbsent;
    }

    public setAvailableApprovedTimeAbsent(availableApprovedTimeAbsent: string): Employee {
        this.availableApprovedTimeAbsent = availableApprovedTimeAbsent;
        return this;
    }

    public getOvertimeTimeAbsentDifference(): string {
        return this.overtimeTimeAbsentDifference;
    }

    public setOvertimeTimeAbsentDifference(overtimeTimeAbsentDifference: string): Employee {
        this.overtimeTimeAbsentDifference = overtimeTimeAbsentDifference;
        return this;
    }

    public getApprovedOvertimeTimeAbsentDifference(): string {
        return this.approvedOvertimeTimeAbsentDifference;
    }

    public setApprovedOvertimeTimeAbsentDifference(approvedOvertimeTimeAbsentDifference: string): Employee {
        this.approvedOvertimeTimeAbsentDifference = approvedOvertimeTimeAbsentDifference;
        return this;
    }

    public getAvailableApprovedOvertimeTimeAbsentDifference(): string {
        return this.availableApprovedOvertimeTimeAbsentDifference;
    }

    public setAvailableApprovedOvertimeTimeAbsentDifference(availableApprovedOvertimeTimeAbsentDifference: string): Employee {
        this.availableApprovedOvertimeTimeAbsentDifference = availableApprovedOvertimeTimeAbsentDifference;
        return this;
    }

    public getAvailableOvertimeInDays(): any {
        return (+this.getAvailableApprovedOvertime() / 8).toFixed(0);
    }

    public setOffDays(offDays: number): Employee {
        this.offDays = offDays;
        return this;
    }

    public getOffDays(): number {
        return this.offDays;
    }

    public getTotalAvailableDays(): number {
        return this.totalAvailableDays;
    }

    public setAvailableOffDays(availableOffDays: number): Employee {
        this.availableOffDays = availableOffDays;
        return this;
    }

    public getAvailableOffDays(): number {
        return this.availableOffDays;
    }

    public getMonthlySalaryCalculationInternalNumber(): string {
        return this.monthlySalaryCalculationInternalNumber;
    }

    public setMonthlySalaryCalculationInternalNumber(monthlySalaryCalculationInternalNumber: string): Employee {
        this.monthlySalaryCalculationInternalNumber = monthlySalaryCalculationInternalNumber;
        return this;
    }

    public getUser(): User|undefined {
        return this.user;
    }

    public setUser(user: User): Employee {
        this.user = user;
        return this;
    }

    public getCompany(): Company|undefined {
        return this.company;
    }

    public setCompany(company: Company): Employee {
        this.company = company;
        return this;
    }

    public setCalendarEntries(calendarEntries: CalendarEntry[]): Employee {
        this.calendarEntries = calendarEntries;
        return this;
    }

    public getCalendarEntries(): CalendarEntry[] {
        return this.calendarEntries || [];
    }

    public setCalendarEntriesFiltered(calendarEntriesFiltered: CalendarEntry[]): Employee {
        this.calendarEntriesFiltered = calendarEntriesFiltered;
        return this;
    }

    public getCalendarEntriesFiltered(): CalendarEntry[] {
        return this.calendarEntriesFiltered || [];
    }

    public setSecondBusinessMobilePhoneNumber(secondBusinessMobilePhoneNumber: string): Employee {
        this.secondBusinessMobilePhoneNumber = secondBusinessMobilePhoneNumber;
        return this;
    }

    public getSecondBusinessMobilePhoneNumber(): string | undefined {
        return this.secondBusinessMobilePhoneNumber;
    }

    public setMedias(medias: MediaObject[]): this {
        this.medias = medias;
        return this;
    }

    public getMedias(): MediaObject[] {
        return this.medias;
    }

    public getMedia(context: string): MediaObject|undefined {
        for (const mediaObject of this.getMedias()) {
            if (mediaObject instanceof MediaObject && mediaObject.getContext() === context) {
                return mediaObject;
            }
        }

        return undefined;
    }

    public setVehicleRides(vehicleRides: VehicleRide[]): Employee {
        this.vehicleRides = vehicleRides;
        return this;
    }

    public getVehicleRides(): VehicleRide[] | undefined {
        return this.vehicleRides;
    }

    public setIsMonthlySalaryCalculationRegulated(isMonthlySalaryCalculationRegulated: boolean): Employee {
        this.isMonthlySalaryCalculationRegulated = isMonthlySalaryCalculationRegulated;
        return this;
    }

    public getIsMonthlySalaryCalculationRegulated(): boolean {
        return this.isMonthlySalaryCalculationRegulated;
    }

    public getYearsOfService(): any {
        return this.yearsOfService;
    }

    public setYearsOfService(yearsOfService: any): Employee {
        this.yearsOfService = yearsOfService;
        return this;
    }

    public setWorkNoteStatisticCurrentYearCommendation(workNoteStatisticCurrentYearCommendation: EmployeeWorkNoteStatistic): this {
        this.workNoteStatisticCurrentYearCommendation = workNoteStatisticCurrentYearCommendation;
        return this;
    }

    public getWorkNoteStatisticCurrentYearCommendation(): EmployeeWorkNoteStatistic|undefined {
        return this.workNoteStatisticCurrentYearCommendation;
    }

    public setWorkNoteStatisticCurrentYearBehaviorWorkplace(workNoteStatisticCurrentYearBehaviorWorkplace: EmployeeWorkNoteStatistic): this {
        this.workNoteStatisticCurrentYearBehaviorWorkplace = workNoteStatisticCurrentYearBehaviorWorkplace;
        return this;
    }

    public getWorkNoteStatisticCurrentYearBehaviorWorkplace(): EmployeeWorkNoteStatistic|undefined {
        return this.workNoteStatisticCurrentYearBehaviorWorkplace;
    }

    public setWorkNoteStatisticCurrentYearTimeWorx(workNoteStatisticCurrentYearTimeWorx: EmployeeWorkNoteStatistic): this {
        this.workNoteStatisticCurrentYearTimeWorx = workNoteStatisticCurrentYearTimeWorx;
        return this;
    }

    public getWorkNoteStatisticCurrentYearTimeWorx(): EmployeeWorkNoteStatistic|undefined {
        return this.workNoteStatisticCurrentYearTimeWorx;
    }

    public setWorkNoteStatisticCurrentYearWorkViolation(workNoteStatisticCurrentYearWorkViolation: EmployeeWorkNoteStatistic): this {
        this.workNoteStatisticCurrentYearWorkViolation = workNoteStatisticCurrentYearWorkViolation;
        return this;
    }

    public getWorkNoteStatisticCurrentYearWorkViolation(): EmployeeWorkNoteStatistic|undefined {
        return this.workNoteStatisticCurrentYearWorkViolation;
    }

    public getSickLeaveCount(): number {
        return this.sickLeaveCount;
    }

    public setSickLeaveCount(sickLeaveCount: number): Employee {
        this.sickLeaveCount = sickLeaveCount;
        return this;
    }

    public getTimeWorxCount(): number {
        return this.timeWorxCount;
    }

    public setTimeWorxCount(timeWorxCount: number): Employee {
        this.timeWorxCount = timeWorxCount;
        return this;
    }

    public getWorkViolationCount(): number {
        return this.workViolationCount;
    }

    public setWorkViolationCount(workViolationCount: number): Employee {
        this.workViolationCount = workViolationCount;
        return this;
    }

    public getCommendationOfSuperiorCount(): number {
        return this.commendationOfSuperiorCount;
    }

    public setCommendationOfSuperiorCount(commendationOfSuperiorCount: number): Employee {
        this.commendationOfSuperiorCount = commendationOfSuperiorCount;
        return this;
    }

    public getBehaviorWorkplaceCount(): number {
        return this.behaviorWorkplaceCount;
    }

    public setBehaviorWorkplaceCount(behaviorWorkplaceCount: number): Employee {
        this.behaviorWorkplaceCount = behaviorWorkplaceCount;
        return this;
    }

    public getOvertimeCount(): number {
        return this.overtimeCount;
    }

    public setOvertimeCount(overtimeCount: number): Employee {
        this.overtimeCount = overtimeCount;
        return this;
    }

    public getAbsenceItemCount(): number {
        return this.absenceItemCount;
    }

    public setAbsenceItemCount(absenceItemCount: number): Employee {
        this.absenceItemCount = absenceItemCount;
        return this;
    }

    public setVehicleRideTotalMileage(vehicleRideTotalMileage: string): this {
        this.vehicleRideTotalMileage = vehicleRideTotalMileage;
        return this;
    }

    public getVehicleRideTotalMileage(): string {
        return this.vehicleRideTotalMileage;
    }

    public setVehicleRideCurrentYearMileage(vehicleRideCurrentYearMileage: string): this {
        this.vehicleRideCurrentYearMileage = vehicleRideCurrentYearMileage;
        return this;
    }

    public getVehicleRideCurrentYearMileage(): string {
        return this.vehicleRideCurrentYearMileage;
    }

    public getVehicleRideItemCount(): number {
        return this.vehicleRideItemCount;
    }

    public setVehicleRideItemCount(vehicleRideItemCount: number): Employee {
        this.vehicleRideItemCount = vehicleRideItemCount;
        return this;
    }

    public setHasPaymentDeductionForMoron(hasPaymentDeductionForMoron: boolean): Employee {
        this.hasPaymentDeductionForMoron = hasPaymentDeductionForMoron;
        return this;
    }

    public getHasPaymentDeductionForMoron(): boolean {
        return this.hasPaymentDeductionForMoron;
    }

    public setTypeOfFirstVaccine(typeOfFirstVaccine: Vaccine): this {
        this.typeOfFirstVaccine = typeOfFirstVaccine;
        return this;
    }

    public getTypeOfFirstVaccine(): Vaccine|undefined {
        return this.typeOfFirstVaccine;
    }

    public setTypeOfSecondVaccine(typeOfSecondVaccine: Vaccine): this {
        this.typeOfSecondVaccine = typeOfSecondVaccine;
        return this;
    }

    public getTypeOfSecondVaccine(): Vaccine|undefined {
        return this.typeOfSecondVaccine;
    }

    public setTypeOfThirdVaccine(typeOfThirdVaccine?: Vaccine): this {
        this.typeOfThirdVaccine = typeOfThirdVaccine;
        return this;
    }

    public getTypeOfThirdVaccine(): Vaccine|undefined {
        return this.typeOfThirdVaccine;
    }

    public setDateOfFirstVaccination(dateOfFirstVaccination: Date): this {
        this.dateOfFirstVaccination = dateOfFirstVaccination;
        return this;
    }

    public getDateOfFirstVaccination(): Date|undefined {
        return this.dateOfFirstVaccination;
    }

    public setDateOfSecondVaccination(dateOfSecondVaccination: Date): this {
        this.dateOfSecondVaccination = dateOfSecondVaccination;
        return this;
    }

    public getDateOfSecondVaccination(): Date|undefined {
        return this.dateOfSecondVaccination;
    }

    public setDateOfTheThirdVaccination(dateOfTheThirdVaccination: Date): this {
        this.dateOfTheThirdVaccination = dateOfTheThirdVaccination;
        return this;
    }

    public getDateOfTheThirdVaccination(): Date|undefined {
        return this.dateOfTheThirdVaccination;
    }

    public setGetOverCovid(getOverCovid: boolean): Employee {
        this.getOverCovid = getOverCovid;
        return this;
    }

    public getGetOverCovid(): boolean {
        return this.getOverCovid;
    }

    public setStartCovidDate(startCovidDate: Date): this {
        this.startCovidDate = startCovidDate;
        return this;
    }

    public getStartCovidDate(): Date|undefined {
        return this.startCovidDate;
    }

    public setEndCovidDate(endCovidDate: Date): this {
        this.endCovidDate = endCovidDate;
        return this;
    }

    public getEndCovidDate(): Date|undefined {
        return this.endCovidDate;
    }

    public setEmployeeTravelExpenses(employeeTravelExpenses: EmployeeTravelExpense[]): Employee {
        this.employeeTravelExpenses = employeeTravelExpenses;
        return this;
    }

    public getEmployeeTravelExpenses(): EmployeeTravelExpense[] | undefined {
        return this.employeeTravelExpenses;
    }

    public getMaternityLeaveCount(): number {
        return this.maternityLeaveCount;
    }

    public setMaternityLeaveCount(maternityLeaveCount: number): Employee {
        this.maternityLeaveCount = maternityLeaveCount;
        return this;
    }
}
